import { defaultOptions } from "./default-options.js"
import { googleAnalyticsInitializeAndTrack } from './index.js'
import merge from "lodash/merge"

// init
export const onClientEntry = (_, pluginOptions = {}) => {
  window.googleAnalyticsAdded = false
  window.googleAnalyticsInitialized = false

  const options = merge(defaultOptions, pluginOptions)
  window.googleAnalyticsOptions = options
}

// track
export const onRouteUpdate = ({ location }) => {
    googleAnalyticsInitializeAndTrack(location)
}
