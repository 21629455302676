import { initializeAndTrackGA } from './services'
import { isGAEnvironmentValid } from './helper.js'

export function googleAnalyticsInitializeAndTrack(location) {
    const options = window.googleAnalyticsOptions
    if (isGAEnvironmentValid(options.environments)) {
        if (location === undefined || location === null) {
            console.error('Please provide a reach router location to the initializeAndTrack function.')
        } else {
            initializeAndTrackGA(options.googleAnalytics, location)
        }
    }
}