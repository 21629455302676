export function validGATrackingId(options)
  {   return options.trackingId &&
  options.trackingId.trim() !== ``; }

export function getCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export function isGAEnvironmentValid(environments) {
  const currentEnvironment = process.env.ENV || process.env.NODE_ENV || `development`
  return environments.includes(currentEnvironment)
}