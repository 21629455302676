module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kurrent Karin - Ihre professionelle Kurrentschrift Übersetzerin","short_name":"Kurrent Karin","lang":"de","start_url":"/","background_color":"#fed7aa","theme_color":"#fed7aa","display":"standalone","icon":"src/images/Logo-512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2cf787d34363e27cd209e48f4665ea2f"},
    },{
      plugin: require('../plugins/gatsby-gdpr-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"environments":["production"],"googleAnalytics":{"trackingId":"G-EVPF8GZGPY","cookieName":"c-google-analytics","anonymize":true,"allowAdFeatures":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.kurrent-karin.at"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
