import { validGATrackingId, getCookie } from '../helper.js'
import { addGA, initializeGA, trackGA } from './google-analytics.js'

export function initializeAndTrackGA(options, location) {
  if (getCookie(options.cookieName) === `true` && validGATrackingId(options)) {
    addGA(options).then((status) => {
      if (status) {
        initializeGA(options)
        trackGA(options, location)
      }
    })
  }
}
